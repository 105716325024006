//Author June Leow
//Date Jul 25th, 2024
import { useParams } from 'react-router';
import {Collapse, Card, CardHeader, CardBody, Col, Row, NavItem, Nav, TabContent, TabPane, NavLink as NavLinkL} from 'reactstrap';
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, getStateCode} from '../../util/util';
import React, {useReducer, useEffect} from 'react';
import MyHeatMapDrillDown from '../util/my-heat-map-drilldown';
import {NavLink} from 'react-router-dom';
import MyReactTable from '../util/my-react-table';

//initialize the state
const initialState = {
  activeTab:'3',
  allAppraisalTypes:[],
  counties:[],
  tempCounties:[],
  usa:[],
  statesStats:[],
  showStatesStats:true,
  heatMapDrillDownState:'',
};




//reducer function that perform state update
const reducer = getReducer();


const TurntimeReport  = (props)=>{
  const controller = new AbortController();

  let paramEncoded = useParams().params;
  let param = atob(paramEncoded);

  let from ='';
  let to ='';
  let stateParam ='';
  let loanPurpose = '';
  let loanType = '';
  let isRush = '';
  let isComplex = '';
  let city = '';
  let entity = '';
  let county = '';
  let appraisalTypes = '';
  let turntime = '1';
  let client = '';
  let openBy = '';
  let showVolume = false;
  let showAppraiser = false;
  let showComplex = false;
  let showSpread = false;

  let tokens = param.split('&');

  for(let i=0;i<tokens.length;i++){
    let tmp = tokens[i].split('=');

    if(tmp.length>1){
      switch(tmp[0]){
        case 'loanPurpose':
          if(tmp[1]&&tmp[1]!='')
            loanPurpose = tmp[1];
          break;
        case 'loanType':
          if(tmp[1]&&tmp[1]!='')
            loanType = tmp[1];
          break;
        case 'turntime':
          if(tmp[1]&&tmp[1]!='')
            turntime = tmp[1];
          break;
        case 'complex':
          if(tmp[1]&&tmp[1]!='')
            isComplex = tmp[1];
          break;
        case 'rush':
          if(tmp[1]&&tmp[1]!='')
            isRush = tmp[1];
          break;
        case 'from':
          if(tmp[1]&&tmp[1]!='')
            from = tmp[1];
          break;
        case 'to':
          if(tmp[1]&&tmp[1]!='')
            to = tmp[1];
          break;
        case 'entity':
          if(tmp[1]&&tmp[1]!='')
            entity = tmp[1];
          break;
        case 'state':
          if(tmp[1]&&tmp[1]!='')
            stateParam = tmp[1];
          break;
        case 'county':
          if(tmp[1]&&tmp[1]!='')
            county = tmp[1];
          break;
        case 'city':
          if(tmp[1]&&tmp[1]!='')
            city = tmp[1];
          break;
        case 'client':
          if(tmp[1]&&tmp[1]!='')
            client = tmp[1];
          break;
        case 'openBy':
          if(tmp[1]&&tmp[1]!='')
            openBy = tmp[1];
          break;
        case 'showVolume':
          if(tmp[1]&&tmp[1]!='')
            showVolume = tmp[1];
          break;
        case 'showAppraiser':
          if(tmp[1]&&tmp[1]!='')
            showAppraiser = tmp[1];
          break;
        case 'showSpread':
          if(tmp[1]&&tmp[1]!='')
            showSpread = tmp[1];
          break;
        case 'showComplex':
          if(tmp[1]&&tmp[1]!='')
            showComplex = tmp[1];
          break;
        case 'appraisalType':

          if(tmp[1]&&tmp[1]!='')
            appraisalTypes = tmp[1];
          break;
      }
    }
  }

  let fromDate = new Date(from);
  let toDate = new Date(to);
  let difference = toDate.getTime() - fromDate.getTime();
  let days = difference / (1000 * 3600 * 24);

  let newInitialState = Object.assign({}, initialState, {
    showVolume:showVolume,
    showAppraiser:showAppraiser,
    showComplex:showComplex,
    showSpread:showSpread,
    loanPurpose:loanPurpose,
    loanType:loanType,
    isRush:isRush,
    isComplex:isComplex,
    state:stateParam,
    county:county,
    city:city,
    entity:entity,
    appraisalTypes:appraisalTypes,
    days:days,
    from:from,
    to:to,
    turntime:turntime,
    client:client,
    openBy:openBy,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal, noToken:true});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReport();
    getAppraisalTypes()
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const activeTabToggle=(tab)=>{
    setState({activeTab:tab});
  }

  const heatMapOnClick=(stateCounty)=>{
    setState({heatMapSelectedStateCounty:stateCounty});
  }

  const heatMapDrillDown=(state)=>{

    if(state===''){
      setState({counties:state.tempCounties.slice(), tempCounties:state.counties.slice(), heatMapDrillDownState:state});
    }
    else{
      let counties = state.counties.slice();
      let newCounties = [];

      for(let i=0;i<counties.length;i++){
        if(counties[i].state===state)
          newCounties.push(counties[i]);
      }

      setState({counties:newCounties, tempCounties:counties, heatMapDrillDownState:state});
    }
  }

  const mapGoBack=()=>{
    setState({heatMapSelectedState:'', counties:state.tempCounties});
  }

  //API call
  const getAppraisalTypes = ()=>{
    let callBack = apiCallBack([{state:'allAppraisalTypes',key:'data'}]);
    httpGet('appraisalType/get/externalLabel', '','Oops, something went wrong and could not load the appraisal type. Please try again later.', callBack);
  }

  const getReport = ()=>{
    setState({statesStats:[],counties:[]});
    let callBack = apiCallBack([{state:'counties',key:'data'},{state:'statesStats',key:'data2'},{state:'usa',key:'data3'}]);
    httpGet('report/turnTime/openBy='+state.openBy+'&client='+state.client+'&turntime='+state.turntime+'&loanPurpose='+state.loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&county='+state.county+'&city='+state.city+'&appraisalType='+state.appraisalTypes, '','Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  //render
  let columns = [
    {
      id: 'state',
      order:'0',
      Header: 'State',
      accessor: d => d.state,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'county',
      Header: 'County',
      order:'1',
      accessor: d => d.county,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'overallAvgTurntime',
      Header: 'Overall avg TAT',
      order:'4',
      accessor: d => d.overall_avg_turntime,
      Cell: props => <div>{props.row.original.overall_avg_turntime} business day</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'unclassified',
      Header: 'Unclassified',
      order:'5',
      accessor: d => d.unknown_avg_turntime,
      Cell: props => <div>{props.row.original.unknown_avg_turntime} business day</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'urban',
      Header: 'Urban',
      order:'6',
      accessor: d => d.urban_avg_turntime,
      Cell: props => <div>{props.row.original.urban_avg_turntime} business day</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'suburban',
      Header: 'Suburban',
      order:'7',
      accessor: d => d.suburban_avg_turntime,
      Cell: props => <div>{props.row.original.suburban_avg_turntime} business day</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'rural',
      Header: 'Rural',
      order:'8',
      accessor: d => d.rural_avg_turntime,
      Cell: props => <div>{props.row.original.rural_avg_turntime} business day</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  let columns2 = [
    {
      id: 'state',
      order:'0',
      Header: 'State',
      accessor: d => d.state,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'overallAvgTurntime',
      Header: 'Overall avg TAT',
      order:'4',
      accessor: d => d.overall_avg_turntime,
      Cell: props => <div>{props.row.original.overall_avg_turntime} business day</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'unclassified',
      Header: 'Unclassified',
      order:'5',
      accessor: d => d.unknown_avg_turntime,
      Cell: props => <div>{props.row.original.unknown_avg_turntime} business day</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'urban',
      Header: 'Urban',
      order:'6',
      accessor: d => d.urban_avg_turntime,
      Cell: props => <div>{props.row.original.urban_avg_turntime} business day</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'suburban',
      Header: 'Suburban',
      order:'7',
      accessor: d => d.suburban_avg_turntime,
      Cell: props => <div>{props.row.original.suburban_avg_turntime} business day</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'rural',
      Header: 'Rural',
      order:'8',
      accessor: d => d.rural_avg_turntime,
      Cell: props => <div>{props.row.original.rural_avg_turntime} business day</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  let complex, spread, volume, appraiser;

  if(state.showVolume==='true'){
    columns.push(
      {
        id: 'overallTotal',
        order:'2',
        Header: 'Total Volume',
        accessor: d => d.overall_total,
        headerStyle: {
          textAlign:'left'
        }
      }
    );

    columns2.push(
      {
        id: 'overallTotal',
        order:'2',
        Header: 'Total Volume',
        accessor: d => d.overall_total,
        headerStyle: {
          textAlign:'left'
        }
      }
    );

    volume = <Col sm="2">
      <label>Total Volume</label><br/>
      {state.usa.overall_total}
    </Col>;

    pointFormat = pointFormat+'{point.overall_total} orders<br/>';
  }

  if(state.showComplex==='true'){
    columns.push(
      {
        id: 'complex',
        order:'9',
        Header: 'Complex',
        accessor: d => d.total_complex,
        headerStyle: {
          textAlign:'left'
        }
      }
    );

    columns2.push(
      {
        id: 'complex',
        order:'9',
        Header: 'Complex',
        accessor: d => d.total_complex,
        headerStyle: {
          textAlign:'left'
        }
      }
    );

    complex = <Col sm="2">
      <label>Complex</label><br/>
      {state.usa.total_complex}
    </Col>;

    pointFormat = pointFormat+'{point.complex_percentage}% complex<br/>';
  }

  if(state.showSpread==='true'){
    columns.push(
      {
        id: 'spread',
        order:'5',
        Header: 'Spread',
        accessor: d => d.spread,
        headerStyle: {
          textAlign:'left'
        }
      }
    );

    columns2.push(
      {
        id: 'spread',
        order:'5',
        Header: 'Spread',
        accessor: d => d.spread,
        headerStyle: {
          textAlign:'left'
        }
      }
    );

    spread = <Col sm="2">
      <label>Spread</label><br/>
      ${state.usa.spread}
    </Col>;

    pointFormat = pointFormat+' ${point.spread} spread<br/>';
  }

  if(state.showAppraiser==='true'){
    columns.push(
      {
        id: 'appraiserTotal',
        Header: 'Available Appraiser',
        order:'3',
        accessor: d => d.appraiser_total,
        headerStyle: {
          textAlign:'left'
        }
      }
    );

    columns2.push(
      {
        id: 'appraiserTotal',
        Header: 'Available Appraiser',
        order:'3',
        accessor: d => d.appraiser_total,
        headerStyle: {
          textAlign:'left'
        }
      }
    );

    appraiser = <Col sm="2">
      <label>Available Appraiser</label><br/>
      {state.usa.appraiser_total}
    </Col>;
  }

  let data = [];
  let data2 = [];
  let map;
  // eslint-disable-next-line no-template-curly-in-string
  let pointFormat = '<span>{point.code}: {point.value} bd<br/>{point.overall_total} orders<br/>{point.complex_percentage}% complex<br/>{point.total_appraiser} appraisers<br/>${point.spread} spread</span>';
  let joinBy =['postal-code','code'];

  //state data
  for(let i=0;i<state.statesStats.length;i++){
    data.push({
      state:state.statesStats[i].state,
      state_short:getStateCode(state.statesStats[i].state),
      code:getStateCode(state.statesStats[i].state),
      value:state.statesStats[i].overall_avg_turntime,
      overall_total: state.statesStats[i].overall_total,
      spread:state.statesStats[i].spread,
      total_lender_fee:state.statesStats[i].total_lender_fee,
      total_appraiser:state.statesStats[i].appraiser_total,
      total_appraiser_fee:state.statesStats[i].total_appraiser_fee,
      total_complex:state.statesStats[i].total_complex,
      complex_percentage:state.statesStats[i].complex_percentage,
    });
  }

  //county data
  for(let i=0;i<state.counties.length;i++){
    let tmp = {
      state:state.counties[i].state,
      state_short:getStateCode(state.counties[i].state),
      county:state.counties[i].county.trim(),
      overall_total: state.counties[i].overall_total,
      spread:state.counties[i].spread,
      total_lender_fee:state.counties[i].total_lender_fee,
      total_appraiser_fee:state.counties[i].total_appraiser_fee,
      total_appraiser:state.counties[i].appraiser_total,
      total_complex:state.counties[i].total_complex,
      complex_percentage:state.counties[i].complex_percentage,
      value:state.counties[i].overall_avg_turntime
    };
    data2.push(tmp);
  }

  if(data.length>0)
    map = <MyHeatMapDrillDown data={data} data2={data2} legendTitle="day" joinBy={joinBy} pointFormat={pointFormat} title="Turnaround Time (days)" onClick={heatMapOnClick}/>;


  columns = columns.sort(function(col1, col2){
    let order1 = parseInt(col1.order,10);
    let order2 = parseInt(col2.order, 10);
    return order1 < order2 ? -1 : 1;
  });

  columns2 = columns2.sort(function(col1, col2){
    let order1 = parseInt(col1.order,10);
    let order2 = parseInt(col2.order, 10);
    return order1 < order2 ? -1 : 1;
  });


  let label;

  switch(state.turntime){
    case '1':
      label = 'Assignment Received - Completed';
      break;
    case '2':
      label = 'Assignment Received - Appraiser Assigned';
      break;
    case '3':
      label = 'Inspection Completed - Completed';
      break;
  }

  let apLabel;

  if(state.appraisalTypes==='')
    apLabel = 'All Products';
  else{
    let appraisalTypes = state.appraisalTypes.split('|');
    let tmp = [];

    for(let i=0;i<appraisalTypes.length;i++){
      for(let j=0;j<state.allAppraisalTypes.length;j++){
        if(state.allAppraisalTypes[j].appraisal_type===appraisalTypes[i]&&tmp.indexOf(state.allAppraisalTypes[j].external_label)===-1)
          tmp.push(state.allAppraisalTypes[j].external_label);
      }
    }

    apLabel = tmp.join(', ');
  }


  return(
    <div>
      <div className="topbar">
          <div className="topbar-logo-container">
            <NavLink to="/"><img className="topbar-logo-img" alt="@Home VMS Logo" width="120px" src="/img/logo_small.png"/></NavLink>
          </div>
      </div>
      <div className="padding">
        <Card>
          <CardHeader className="header-color">
            <i className="fa fa-reorder"></i>&nbsp;{label} Turntime Report
          </CardHeader>
          <CardBody>
            <center>
              <label>{label}</label><br/>
              <label>{state.from+' - '+state.to} ({state.days} days)</label>
              <br/>
              <label>{apLabel}</label>
            </center>
            <br/>
            <Card>
              <CardHeader className="header-color cursor-pointer" onClick={()=>setState({showStatesStats:!state.showStatesStats})}>
                <Row>
                  <Col sm="8">
                    <i className="fa fa-edit"></i>&nbsp;States Stats
                  </Col>
                  <Col sm="4" className="align-right">
                    <i className={(state.showStatesStats===false)?"fa fa-chevron-up":"fa fa-chevron-down"}></i>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Collapse isOpen={state.showStatesStats}>
                  {map}
                </Collapse>
              </CardBody>
            </Card>

            <br/><div className="my-divider">&nbsp;</div>
            <Card>
              <CardHeader className="header-color">
                USA Overall
              </CardHeader>
              <CardBody>
                <Row>
                  {volume}
                  {appraiser}
                  {complex}
                  {spread}
                </Row>
                <Row>
                  <Col sm="2">
                    <label>Overall TAT</label><br/>
                    {state.usa.overall_avg_turntime} business day
                  </Col>
                  <Col sm="2">
                    <label>Unclassified</label><br/>
                    {state.usa.unknown_avg_turntime} business day
                  </Col>
                  <Col sm="2">
                    <label>Urban</label><br/>
                    {state.usa.urban_avg_turntime} business day
                  </Col>
                  <Col sm="2">
                    <label>Suburban</label><br/>
                    {state.usa.suburban_avg_turntime} business day
                  </Col>
                  <Col sm="2">
                    <label>Rural</label><br/>
                    {state.usa.rural_avg_turntime} business day
                  </Col>
                </Row>

              </CardBody>
            </Card>
            <br/>

            <Nav tabs>
              <NavItem>
                <NavLinkL
                  className={"cursor-pointer nav-link "+(state.activeTab === '2'?"active":"inactive" )}
                  onClick={() => { activeTabToggle('2'); }}
                  to="#"
                >
                  State Level
                </NavLinkL>
              </NavItem>
              <NavItem>
                <NavLinkL
                  className={"cursor-pointer nav-link "+(state.activeTab === '3'?"active":"inactive" )}
                  onClick={() => { activeTabToggle('3'); }}
                  to="#"
                >
                  County Level
                </NavLinkL>
              </NavItem>
            </Nav>
            <br/>
            <TabContent activeTab={state.activeTab}>
              <TabPane tabId="2">
                <MyReactTable columns={columns2} data={state.statesStats} defaultSorted={[ { id: 'state', desc: false } ]} className="table table-striped"/>
              </TabPane>
              <TabPane tabId="3">
                <MyReactTable columns={columns} data={state.counties} defaultSorted={[ { id: 'state', desc: false } ]} className="table table-striped"/>
              </TabPane>
            </TabContent>

          </CardBody>
        </Card>
      </div>
    </div>
  );
}


export default TurntimeReport;
