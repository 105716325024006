//Author June Leow
//Date Jul 25th, 2024
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatNumber, formatDate} from '../../util/util';
import {Card, CardHeader, CardBody, Col, Row} from 'reactstrap';
import moment from 'moment';
import MyChart from '../util/my-chart';
import {NavLink, useParams} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';

//initialize the state
const initialState = {
  selectedAppraisalType:'',
  filterAppraisalTypes:[],
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  loanTypes:[{key:'Conventional',value:'Conventional'},{key:'High Balanced',value:'High Balanced'},{key:'Jumbo',value:'Jumbo'},{key:'Jumbo ARM',value:'Jumbo ARM'},{key:'Jumbo Fixed',value:'Jumbo Fixed'},{key:'Jumbo <$1m',value:'Jumbo <$1m'},{key:'Jumbo $1m - $2m',value:'Jumbo $1m - $2m'},{key:'Jumbo >$2m',value:'Jumbo >$2m'},{key:'FHA',value:'FHA'},{key:'USDA',value:'USDA'},{key:'Other',value:'Other'}],
  loanPurposes:[{key:'Purchase',value:'Purchase'},{key:'Refinance',value:'Refinance'},{key:'R/T Refinance',value:'R/T Refinance'},{key:'C/O Refinance',value:'C/O Refinance'},{key:'HELOC/2nd Mortgage',value:'HELOC/2nd Mortgage'},{key:'Other',value:'Other'}],
  currentDate:{
    total_business_day:0,
    total:0,
    total_lender_fee:0,
    total_appraiser_fee:0,
    spread:0
  },
  mtd:{
    total_business_day:0,
    total:0,
    total_lender_fee:0,
    total_appraiser_fee:0,
    spread:0
  },
  ytd:{
    total_business_day:0,
    total:0,
    total_lender_fee:0,
    total_appraiser_fee:0,
    spread:0
  },
  months:[],

  clientBranches:[],
  clients:[],

  monthsPrior:[],
  monthsLabel:'',
  monthsPriorLabel:'',
  daysLabel:'',
  daysPriorLabel:'',
  dayByDay:[],
  dayByDayPrior:[],

};

//reducer function that perform state update
const reducer = getReducer();


const PublicOrderVolumeReport  = (props)=>{
  const controller = new AbortController();

  let today = new Date();
  let month = today.getMonth()+1;

  if(month<10)
    month = '0'+month;

  let paramEncoded = useParams().params;
  let param = atob(paramEncoded);

  let stateParm ='';
  let loanPurpose = '';
  let loanType = '';
  let isRush = '';
  let isComplex = '';
  let city = '';
  let entity = '';
  let appraisalTypes = '';
  let excludeQuoteHold = '1';
  let client = '';
  let clientBranch = '';
  let openBy = '';
  let date = '';

  let tokens = param.split('&');

  for(let i=0;i<tokens.length;i++){
    let tmp = tokens[i].split('=');

    if(tmp.length>1){
      switch(tmp[0]){
        case 'loanPurpose':
          if(tmp[1]&&tmp[1]!='')
            loanPurpose = tmp[1];
          break;
        case 'clientBranch':
          if(tmp[1]&&tmp[1]!='')
            clientBranch = tmp[1];
          break;
        case 'loanType':
          if(tmp[1]&&tmp[1]!='')
            loanType = tmp[1];
          break;
        case 'excludeQuoteHold':
          if(tmp[1]&&tmp[1]!='')
            excludeQuoteHold = tmp[1];
          break;
        case 'complex':
          if(tmp[1]&&tmp[1]!='')
            isComplex = tmp[1];
          break;
        case 'rush':
          if(tmp[1]&&tmp[1]!='')
            isRush = tmp[1];
          break;
        case 'date':
          if(tmp[1]&&tmp[1]!='')
            date = tmp[1];
          break;
        case 'entity':
          if(tmp[1]&&tmp[1]!='')
            entity = tmp[1];
          break;
        case 'state':
          if(tmp[1]&&tmp[1]!='')
            stateParm = tmp[1];
          break;
        case 'city':
          if(tmp[1]&&tmp[1]!='')
            city = tmp[1];
          break;
        case 'client':
          if(tmp[1]&&tmp[1]!='')
            client = tmp[1];
          break;
        case 'openBy':
          if(tmp[1]&&tmp[1]!='')
            openBy = tmp[1];
          break;
        case 'appraisalType':

          if(tmp[1]&&tmp[1]!='')
            appraisalTypes = tmp[1];
          break;
      }
    }
  }
    
  let newInitialState = Object.assign({}, initialState, {
    date:date,
    excludeQuoteHold:excludeQuoteHold,
    client:client,
    openByClient:openBy,
    clientBranch:clientBranch,
    loanPurpose:loanPurpose,
    loanType:loanType,
    isRush:isRush,
    isComplex:isComplex,
    state:stateParm,
    city:city,
    entity:entity,
    appraisalTypes:appraisalTypes,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal, noToken:true});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReport();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const getReport=()=>{
    let appraisalType = state.filterAppraisalTypes.join('|');
    appraisalType = appraisalType.replace(new RegExp('/','g'),'fslash');
    appraisalType = appraisalType.replace(new RegExp('[\+]','g'),'plus');
    let url = 'report/public/orderVolume/excludeQuoteHold='+state.excludeQuoteHold+'&client='+state.client+'&openBy='+state.openByClient+'&clientBranch='+state.clientBranch+'&loanPurpose='+state.loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&date='+state.date+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;
    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        let currentDate = response.data.data.current_date;
        let mtd = response.data.data.mtd;
        let ytd = response.data.data.ytd;
        let months = response.data.data.months_prior;
        let monthsPrior = response.data.data.months_prior_year;
        let monthsLabel = response.data.data.months_prior_label;
        let monthsPriorLabel = response.data.data.months_prior_year_label;
        let dayByDay = response.data.data.day_by_day;
        let dayByDayPrior = response.data.data.day_by_day_prior_month;
        let daysLabel = response.data.data.day_by_day_label;
        let daysPriorLabel = response.data.data.day_by_day_label_prior_year;

        setState({
          currentDate:currentDate,
          mtd:mtd,
          ytd:ytd,
          months:months,
          monthsPrior:monthsPrior,
          monthsLabel:monthsLabel,
          monthsPriorLabel:monthsPriorLabel,
          dayByDay:dayByDay,
          dayByDayPrior:dayByDayPrior,
          daysLabel:daysLabel,
          daysPriorLabel:daysPriorLabel
        });
      }
    };

    httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  //render
  let dateValue = null;
  if(state.date!=='')
    dateValue = moment(state.date).toDate();

  let chart;
  let chart2;

  if(state.months.length>0){
    let monthsLabel = [];
    let monthsData = [];
    let monthsDataPrior = [];

    let daysLabel = [];
    let daysData = [];
    let daysDataPrior = [];

    for(let i=0;i<state.dayByDayPrior.length;i++){
      daysLabel.push(state.dayByDayPrior[i].date);
      daysDataPrior.push(state.dayByDayPrior[i].count);
    }

    for(let i=0;i<state.dayByDay.length;i++){
      daysData.push(state.dayByDay[i].count);
    }

    for(let i=0;i<state.months.length;i++){
      monthsLabel.push(state.months[i].label);
      monthsData.push(state.months[i].total);
    }

    for(let i=0;i<state.monthsPrior.length;i++){
      monthsDataPrior.push(state.monthsPrior[i].total);
    }
    console.log(state.monthsLabel)
    let priorMonthsConfig = {
      xAxis: {
        categories: monthsLabel
      },
      series: [
        {
          data: monthsData,
          name:'Order Volume '+state.monthsLabel
        },
        {
          data: monthsDataPrior,
          name:'Order Volume '+state.monthsPriorLabel
        }
      ],
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          }
        }
      },
      title:{
        text: 'Order Volume'
      }
    }

    let dayByDayConfig = {
      xAxis: {
        categories: daysLabel
      },
      series: [
        {
          data: daysData,
          name:'Order Volume '+state.daysLabel
        },
        {
          data: daysDataPrior,
          name:'Order Volume '+state.daysPriorLabel
        }
      ],
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          }
        }
      },
      title:{
        text: 'Order Volume Day By Day'
      }
    }

    chart = <MyChart options = {priorMonthsConfig} ></MyChart>;
    chart2 = <MyChart options = {dayByDayConfig} ></MyChart>;
  }

  let apMap = {};
  let currentDateMap = {};
  let mtdMap = {};
  let ytdMap = {};
  let monthMap = [];
  if(state.currentDate.data)
    for(let i=0;i<state.currentDate.data.length;i++){
      currentDateMap[state.currentDate.data[i].appraisal_type] = state.currentDate.data[i].count;
      apMap[state.currentDate.data[i].appraisal_type] = state.currentDate.data[i].count;
    }

  if(state.mtd.data)
    for(let i=0;i<state.mtd.data.length;i++){
      mtdMap[state.mtd.data[i].appraisal_type] = state.mtd.data[i].count;
      apMap[state.mtd.data[i].appraisal_type] = state.mtd.data[i].count;
    }

  if(state.ytd.data)
    for(let i=0;i<state.ytd.data.length;i++){
      ytdMap[state.ytd.data[i].appraisal_type] = state.ytd.data[i].count;
      apMap[state.ytd.data[i].appraisal_type] = state.ytd.data[i].count;
    }

  if(state.months.length>0)
    for(let i=0;i<state.months.length;i++){
      let month = {};
      if(state.months[i].data){
        for(let j = 0;j<state.months[i].data.length;j++){
          month[state.months[i].data[j].appraisal_type] = state.months[i].data[j].count;
          apMap[state.months[i].data[j].appraisal_type] = state.months[i].data[j].count;
        }
      }

      monthMap.push(month);
    }

  let content;

  let sortedAPList = [];
  Object.getOwnPropertyNames(apMap).map(
    (appraisalType,index)=>{
      let tmp = {};
      tmp.appraisalType = appraisalType;
      tmp.count = apMap[appraisalType];

      sortedAPList.push(tmp);
    }
  );

  sortedAPList.sort((a, b)=>{
    return a.appraisalType.localeCompare(b.appraisalType);
  });


  return(
    <div>
      <div className="topbar">
          <div className="topbar-logo-container">
            <NavLink to="/"><img className="topbar-logo-img" alt="@Home VMS Logo" width="120px" src="/img/logo_small.png"/></NavLink>
          </div>
      </div>
      <div className="padding">
        <Card>
          <CardHeader className="header-color">
            <i className="fa fa-reorder"></i>&nbsp;Order Volume Report
          </CardHeader>
          <CardBody>
            <br/>
            <Row style={{gridAutoRows:'1fr'}}>
              <Col sm="4" style={{display:'flex'}}>
                <Card style={{alignItems:'stretch', width:'100%'}}>
                  <CardHeader className="header-color">
                    <i className="fa fa-reorder"></i>&nbsp;{state.currentDate.label}
                  </CardHeader>
                  <CardBody>
                    <label>Duration</label><br/>
                    {formatDate(state.currentDate.from)+' to '+formatDate(state.currentDate.to) }<b>{' ('+state.currentDate.total_business_day+' business days)'}</b>
                    <br/>

                    <label>Total order</label><br/>
                    {state.currentDate.total}
                    <br/>

                  </CardBody>
                </Card>
              </Col>
              <Col sm="4" style={{display:'flex'}}>
                <Card style={{alignItems:'stretch', width:'100%'}}>
                  <CardHeader className="header-color">
                    <i className="fa fa-reorder"></i>&nbsp;{state.mtd.label}
                  </CardHeader>
                  <CardBody>
                    <label>Duration</label><br/>
                    {formatDate(state.mtd.from)+' to '+formatDate(state.mtd.to) }<b>{' ('+state.mtd.total_business_day+' business days)'}</b>
                    <br/>

                    <Row>
                      <Col sm="6">
                        <label>Total order</label><br/>
                        {state.mtd.total}
                      </Col>
                      <Col sm="6">
                        <label>Avg Orders Per Day </label><br/>
                        {formatNumber(state.mtd.total/state.mtd.total_business_day)}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="4" style={{display:'flex'}}>
                <Card style={{alignItems:'stretch', width:'100%'}}>
                  <CardHeader className="header-color">
                    <i className="fa fa-reorder"></i>&nbsp;{state.ytd.label}
                  </CardHeader>
                  <CardBody>
                    <label>Duration</label><br/>
                    {formatDate(state.ytd.from)+' to '+formatDate(state.ytd.to) }<b>{' ('+state.ytd.total_business_day+' business days)'}</b>
                    <br/>

                    <Row>
                      <Col sm="6">
                        <label>Total order</label><br/>
                        {state.ytd.total}
                      </Col>
                      <Col sm="6">
                        <label>Avg Orders Per Day </label><br/>
                        {formatNumber(state.ytd.total/state.ytd.total_business_day)}
                      </Col>
                    </Row>

                  </CardBody>
                </Card>
              </Col>
            </Row>

            <br/>
            <Row>
              <Col sm="12">
                {chart}
                <br/>
                {chart2}
              </Col>
            </Row>

            <br/>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th></th>
                  <th>{state.currentDate.label}</th>
                  <th>{state.mtd.label}</th>
                  <th>{state.ytd.label}</th>
                  {
                    state.months.map(
                      (month,index)=>{
                        return <th key={index}>{month.label}</th>
                      }
                    )
                  }
                </tr>
              </thead>
              <tbody>
              {
                sortedAPList.map(
                  (appraisalType,index)=>{
                    let ap = appraisalType.appraisalType;
                    let monthsOrderVolume = monthMap.map(
                      (month, index2)=>{
                        return (
                          <td key={index2}>
                            {month[appraisalType.appraisalType]?month[appraisalType.appraisalType]:0}
                          </td>
                        )
                      }
                    )

                    return(
                      <tr key={index}>
                        <td>{ap}</td>
                        <td>{currentDateMap[appraisalType.appraisalType]?currentDateMap[appraisalType.appraisalType]:0}</td>
                        <td>{mtdMap[appraisalType.appraisalType]?mtdMap[appraisalType.appraisalType]:0}</td>
                        <td>{ytdMap[appraisalType.appraisalType]?ytdMap[appraisalType.appraisalType]:0}</td>
                        {monthsOrderVolume}
                      </tr>
                    )
                  }
                )
              }
              </tbody>
            </table>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}


export default PublicOrderVolumeReport;
